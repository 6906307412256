#hiddenDiv {
  opacity: 0;
  transform: scalex(0) scale(0);
  position: absolute;
  transition: all 0.3s;
}

/* #btn[disabled]:hover + #hiddenDiv {
    display: block; 
 transform: scaleX(1) scale(1);
 opacity: 1;


} */

.main {
  margin-top: 0.5rem;
  width: 150px;
  height: 30px;
  background-color: #f93e5d;
}

.main .shape {
  height: 10px;
  width: 10px;
  position: absolute;
  transform: rotate(45deg);
  top: -5px;
  right: 20px;
  border-right: none;
  border-bottom: none;
  background-color: #f93e5d;
}
select__menu-list::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
select__menu-list::-webkit-scrollbar-thumb {
  background: #888;
}
select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
