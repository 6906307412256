.form-container {
  width: 400px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.input-people-database {
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid #d3dae3;

}

.people-database-btn {
  border-radius: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 38px;
  color: white;
  background-color: #583cea;
}
.input-people-database:focus {
  outline: none;
  border-color: rgb(51, 154, 240);
}