.css-19kzrtu{
    padding:20px 0 !important;
}

.css-7obqij-MuiInputBase-root-MuiInput-root:before{
    border-bottom:2px solid #ced4da !important;
}



.border-bottom-input {
    border: 1px #dadbde solid;
    padding: 12px 20px;
    min-height: 48px;
    border-radius: 8px !important;
}

.border-bottom-input:focus {
    outline: none;
  border-color: #006bff;

    transition: all .3s;
}



.simplebar-scrollbar::before {
    background-color: white !important;
  }
  

