/* Table styles */
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: white;
  font-weight: bold;
  color: #333;
}

/* Sorting styles */
th.sortable {
  position: relative;
}

th.sortable::after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

th.sortable:hover::after {
  border-top-color: #333;
}

th.sorted-asc::after {
  border-top-color: #333;
}

th.sorted-desc::after {
  border-bottom-color: #333;
}

/* Filtering styles */
th.filterable {
  position: relative;
}

th.filterable input {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 80%;
  padding: 5px;
  border: none;
  border-radius: 3px;
  background-color: #f2f2f2;
  font-size: 14px;
}

th.filterable input:focus {
  outline: none;
  box-shadow: 0 0 3px #333;
}

/* Pagination styles */
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}

.page-link {
  display: block;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 0 5px;
  text-decoration: none;
  color: #333;
}

.page-link.active,
.page-link:hover {
  background-color: #333;
  color: #fff;
}

.name-div {
  display: flex;
  align-items: center;
}
