.langding-page-heading {
  font-family: "Paytone One", sans-serif;
}

.landing-btn {
  color: #fff;
  letter-spacing: 0.2px;
  cursor: pointer;
  background-color: #242222;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 9px 17px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  box-shadow: 0 6px #000;
}

.large-underline {
  background-image: url(https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61b71e10f88d963a422254ce_large_underline.svg);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: -4px;
  padding-bottom: 10px;
  text-decoration: none;
  transition: all 0.25s;
  display: inline-block;
}

.button-sm {
  letter-spacing: 0.2px;
  cursor: pointer;
  border: 2px solid #1b2644;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 9px 17px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  box-shadow: 0 4px #1b2644;
}


.box-title {
  letter-spacing: .4px;

  font-size: 25px;
  font-weight: 700;
  line-height: 44px;

}