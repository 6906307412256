.filter {
  &__control{

    border-color: #d3dae3;

    &--is-focused{
      // border: 1px solid red;
    }
  }
    &__menu {
      margin: 0.125rem auto;
    }
  
    &__multi-value{
      font-size: 12px !important;
    }
    &__option {
      background-color: white;
  
     
    }
  
    &__input-container {
      padding: 0;
    }
  
    &__single-value {
      font-size: 14px;
    }
  }

 .react-datepicker{
  &__input-container{
    border: 1px solid #d3dae3;
    padding: 5px 8px;
    width: 100%;
    
  }
  & input{
    width: 100%;
    
 }
}
 
 .react-datepicker-wrapper{
  width: 100%;
 }
 .react-datepicker__input-container input{
  width: 100% !important;
}