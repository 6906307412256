.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  outline: 0;
  /* -webkit-overflow-scrolling: touch; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.modal-empty-div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background-color: #242d3e;
  opacity: 0.8;
}

.content-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: inherit;
  /* animation: fadeinDown; */
  overflow-y: auto;
  
}

/* .content-container::-webkit-scrollbar {
  width: 0.3em;
  width: 8px;
  position: absolute;
  right: 0;
  top: 0;
}

.content-container::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 1rem;
}

.content-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 1rem;
} */

.popup-content {
  box-shadow: 0px 4px 9px rgb(0 0 0 / 9%);
  position: relative;
  border-radius: 4px;
  background-clip: padding-box;
  background-color: #fff;
  outline: 0;
}

.radius-top {
  /* border-radius: 20px 20px 0px 0px; */
}

.grid-item-shadow {
  box-shadow: 0 3px 1rem rgb(0 0 0 / 10%);
}

.border-template {
  border: 2px solid #5d5fef;
}

.modal-shadow {
  box-shadow: 1px 23px 23px -9px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 23px 23px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 23px 23px -9px rgba(0, 0, 0, 0.75);
}

.disabled-btn {
  background-color: #dfdddd !important;
  color: #aeaeae !important;
  /* cursor: no-drop !important; */
}

.messege-box {
  opacity: 0;
}
/* .disabled-btn:hover .popup .messege-box {
  background-color: #dfdddd !important;
  opacity: 1;
  transition: all 0.2s;
} */

.input-shadow {
  box-shadow: 10px, 4px rgba(75, 72, 72, 0.3) !important;
}

@keyframes fadeinDown {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  80% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;

    transform: scale(1);
    transition: 0.3s !important;
  }
}

.button:active {
  transform: scale(0.9);
  transition: all 0.3s;
}

/* // sognature modal  */
