.deep-shadow {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 20%);
}

.input-customize {
  border: 1px solid rgb(206, 212, 218);

  font-family: "Hind Siliguri", sans-serif;
  height: 40px;
  -webkit-tap-highlight-color: transparent;
  line-height: 34px;
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: rgb(0, 0, 0);
  display: block;
  text-align: left;
  min-height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
}

.input-customize:focus {
  outline: none;
  border-color: rgb(51, 154, 240);
}



.label {
  padding: 0.5rem 0.25rem;
  display: inline-block;
}

.input-border {
  border: 1px solid rgb(206, 212, 218);
}
