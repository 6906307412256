select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 13px;
}

#stepNumber,
#klubStactProperites,
.days-input,
.hour-input,
#channel:focus {
  outline: none;
}

.ql-container.ql-snow {
  border: 1px solid #d3dae3 !important;
  /* border-bottom: 1px solid transparent !important; */
  background-color: #fff;
  font-size: 17px;
}

.border-blue {
  border: 1px solid #3b59df;
}

.ql-toolbar.ql-snow {
  border: 1px solid #d3dae3 !important;
  border-top: 0;
  background-color: #fff;
}

.ql-editor:focus,
.ql-editor.ql-blank:focus {
  outline: none;
}
.ql-editor {
  transform: translateZ(0);
}

#hiddenSubject {
  display: none !important;
}

.shadow-personalize {
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  border: 1px solid #ddd;
}

.border-personalize {
  /* border-bottom: 2px solid #d3dae3; */
  padding-left: 10px;
}

.ql-editor {
  font-size: 0.9rem !important;
}

.custom-brace-icon {
  border: 1px solid #d3dae3;
}

.preview-container .ql-editor {
  background-color: #f7f9fb;
  padding: 0 !important;
}

.preview-container .ql-container.ql-snow {
  border: 0 !important;
}
.ql-snow .ql-tooltip {
  left: 30px !important;
}

.ql-snow .ql-tooltip input:focus {
  outline: 1px solid #3b59df !important;
}

.sumon {
  height: calc(100vh-200px) !important;
}

/* tox editior  */
.tox .tox-statusbar {
  display: none !important;
}

/* draft  */
.preview-container .rdw-editor-main {
  height: 100% !important;
}
.rdw-editor-main {
  /* height: 18rem !important; */
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1;

  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
}
