@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: rgb(197, 197, 197);
  --secondary: rgb(70, 127, 233);
}



@layer components {
  .btn-outline {
    @apply flex rounded-[5px]  border px-5  text-sm hover:bg-[#4349f0] hover:text-white transition-all duration-300 items-center h-[40px] border-[#ced4da];
  }
  .otp-box {
    @apply absolute bg-slate-300 shadow-lg rounded-lg w-[800px] h-[400px] top-[30vh] left-[28vw];
  }

  .close-btn {
    @apply float-right mr-5 mt-3 rotate-45 font-semibold text-4xl hover:cursor-pointer;
  }
  .button {
    @apply bg-[#006bff] text-white font-medium   h-[32px] rounded-[3px] px-[12px] text-[13px];
  }
}
