.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 8px 14px !important;
    font-weight: 600 !important;
    color: #333333 !important;
}

.css-4jnixx-MuiStack-root{
    padding-top: 0 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    overflow: hidden !important;
}