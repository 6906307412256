input::-ms-reveal {
  display: none;
}

.expired {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: inline-flex;
  border-radius: 3px;
  height: 20px;
  white-space: nowrap;
  text-align: center;
  padding: 2px 6px;

  color: #fff;

  border: 1px solid rgba(0, 0, 0, 0);
}
