.apexcharts-menu-icon {
  display: none !important;
}
.apexcharts-toolbar {
  display: none !important;
}

/* .progress-bar {
    transition: width 0.3s ease-in-out;
}

@keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: data.interaction;
    }
  }
  
  .progress-bar {
      animation: progress 1s ease-in-out;
  }
   */

.table-progess td {
  padding-top: 0 !important;
}
