.prospectus-item {
  animation: fadeindown 0.3s;
}

@keyframes fadeindown {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.border-dashed {
  border: 2px dashed #6784f4;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;

  cursor: pointer;
  background-color: #3b59df;
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.color-link {
  color: #3b59df;
}

.upload-complete,
.upload-complete-image {
  animation: fadeScale 0.3s;
}

.fadeindown {
  animation: fadeindown 0.3s;
}

@keyframes fadeScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.border-column {
  border: 1px solid #3b59df;
}

.modal {
  display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog {
  overflow-y: initial !important;
}
.modal-body {
  height: 80vh;
  overflow-y: auto;
}

.overflow-Y {
  overflow-y: initial !important;
}

.back-button {
  border: 1px solid #3b59df;
}

.pagiantion {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ddd;
}

.pagination-item {
  border: 2px solid #5d5fef;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  border: 2px solid #5d5fef;

  display: flex;
  justify-content: center;
  align-items: center;
}

.active-page {
  background-color: #3b59df;
  border: 0;
  color: white;
  transition: all 0.2s;
}

.active,
.active-button {
  color: #3b59df;
}

.checkbox-container {
  display: inline-block;
  position: relative;

  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;

  border: 2px solid #d3dae3;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #3b82f6;
  border: 1px solid #ddd;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Webkit browsers */
.scrollbar-container::-webkit-scrollbar {
  width: 0.3em;
  height: 10px;
  background-color: #f5f5f5;
}

.scrollbar-container::-webkit-scrollbar-thumb {
  background-color: #a29f9f;
  border-radius: 10px;
}

/* Firefox */
.scrollbar-container scrollbar {
  width: 0.3em;
  background-color: #f5f5f5;
}

.scrollbar-container scrollbar-thumb {
  background-color: #acabab;
}

/* set height of scrollbar track to 6px */
::-webkit-scrollbar-track {
  width: 6px;
}
/* set height of scrollbar thumb to 6px */
::-webkit-scrollbar-thumb {
  height: 6px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /*safari and chrome*/
  select {
    height: 30px;
    line-height: 30px;
    background: #f4f4f4;
  }
}
select::-moz-focus-inner {
  /*Remove button padding in FF*/
  border: 0;
  padding: 0;
}
@-moz-document url-prefix() {
  /* targets Firefox only */
  select {
    padding: 15px 0 !important;
  }
}
@media screen\0 {
  /* IE Hacks: targets IE 8, 9 and 10 */
  select {
    height: 30px;
    line-height: 30px;
  }
}





.hover-parent .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
  top: 385px !important;
}