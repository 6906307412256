.checkbox-field {
  opacity: 0;
}

.field-group {
  margin-bottom: 0.5rem;
}

.checkbox-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.checkbox-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #fff;
  border: 2px solid #ddd;
}

.checkbox-label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 3.5px;
  width: 8px;
  height: 10px;
  border: solid #1991eb;
  border-width: 0 3px 3px 0;
  transition: 0.2s ease;
}

.checkbox-field:not(:checked) + .checkbox-label::after {
  transform: scale(0);
  opacity: 0;
}
.checkbox-field:checked + .checkbox-label::after {
  transform: rotate(35deg) scale(1);
  opacity: 1;
}


.close-icon{
  display: inline-block;
  transform: scale(.9);
  transition:all .2s;


}

.close-icon:hover{
  transform: rotate(360deg) scale(1);
}