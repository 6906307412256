/* .active-link {
  background-color: red;
} */
.logo-img img {
  filter: brightness(0) invert(1);
  /* or to blue */
}

.active-btn {
  height: 45px;
  border-radius: 7px;
  padding-left: 10px;

  /* filter: drop-shadow(); */
}

.remove {
  display: inline-block !important;
}

.sidebar-new {
  position: fixed;

  /* top: 70px; */
  bottom: 0px;
}
.pro-menu-item a.active {
  color: rgba(0, 0, 255, 0.708) !important; /* put any color you want */
  font-weight: 500;
}

.ps-menu-icon.ps-active.css-wx7wi4.icon {
  color: blue !important;
}


.tooltip {
  z-index:999999999 !important;
}