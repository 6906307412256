.root,
#root,
#docs-root {
  --primary-color: #fff;
  --secondary-color: rgb(186, 186, 30);
  --primary-bg: #f6f9fb;
  --btn-bg: #5d5fef;
  --border-color: #e8eef4;
}

body {
  /* font-family: "Lexend Deca", sans-serif; */
  /* font-family: "Montserrat"; */
  /* font-family: "Catamaran", sans-serif; */
  font-family: "Poppins", sans-serif;
  font-weight: 500 !important;
  font-size: 14px;
}

*{
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
}
/* For Hiding Arrow in Number Input Field */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
}
.custom-shadow-1 {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.custom-shadow-2 {
  box-shadow: 0px 4px 9px rgb(0 0 0 / 9%);
}

.shadow-deep {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.custom-shadow-3 {
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);
}

.primary-heading-color {
  color: #242d3e;
}

.color-active {
  color: #255bff;
}

.border-gray {
  color: #d3dae3;
}

.color-gray {
  color: #5d6a7e;
}
.color-gray-2 {
  color: #949fb1;
}

.icon-color {
  color: #5d6a7e;
}
.stats-color {
  color: #5d6a7e;
}

.color-blue {
  color: #1991eb;
}

.blue-bg {
  background-color: #1991eb;
}

.input-border {
  border: 1px solid #d3dae3;
}

.button-background {
  background: #056EE9;
}

.button-background:hover {
  background-color: #1B84FF;
  transition: all .2S;
}

.font-500 {
  font-weight: 500;
}
.card-border {
  color: #d3dae3;
}

.bg-dark-blue {
  background: #5d5fef;
}

/* draft editor css  */

.draft-editor .rdw-editor-wrapper {
  border: 1px solid #d3dae3 !important;
}

.public-DraftStyleDefault-block {
  margin: 2px 0 !important;
}

.DraftEditor-root {
  padding-left: 5px !important;
}
.rdw-link-decorator-wrapper {
  color: blue !important;
  font-weight: 600 !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.rdw-link-decorator-wrapper {
  border-top: 0 !important;
}

.rdw-editor-main {
  overflow-x: hidden;
}

.rdw-link-modal {
  padding: 10px !important;
}

.rdw-link-modal-buttonsection {
  width: 100% !important;
}

.rdw-link-modal {
  border: 1px solid #d3dae3 !important;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12) !important;
  height: 200px !important;
}
.rdw-link-modal-input {
  margin: 3px 10px !important;
  border: 1px solid #d3dae3 !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
  font-size: 13px !important;
}

.rdw-link-modal-btn {
  width: 60px !important;
  height: 28px !important;
  font-size: 14px !important;
}

.rdw-link-modal-label,
.rdw-link-modal-target-option {
  margin-bottom: 5px;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5d6a7e !important;
}

.rdw-link-modal-btn {
  margin: 0 !important;
}

.rdw-link-modal-btn:last-child {
  margin-left: 15px !important;
  border: 1px solid #d3dae3 !important;
  background-color: white !important;
  color: #5d6a7e !important;
}
.rdw-link-modal-btn:last-child:hover {
  outline: 1px solid #1991eb !important;
}

.rdw-link-modal-btn {
  background-color: #1991eb !important;
  color: white !important;
  border-radius: 3px !important;
}
.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px 0px #fff !important;
}
.rdw-fontsize-dropdown {
  width: 45px !important;
}

.rdw-editor-toolbar {
  border: 0 !important;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.1),
    0 8px 8px -4px rgba(34, 47, 62, 0.07) !important;
}

.rdw-option-active {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;

  background: gainsboro;
  height: 100%;
}

.rdw-colorpicker-wrapper .rdw-option-active {
  height: auto !important;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
}

.colorPickerIcon {
  width: 30px;
  height: 30px;
}

.rdw-colorpicker-modal-options {
  overflow: visible !important;
}

.rdw-colorpicker-modal {
  width: 208px !important;
  height: 276px !important;
}

.rdw-colorpicker-cube {
  border-radius: 50% !important;
  border: 0 !important;
}

.rdw-colorpicker-option {
  box-shadow: 0px 0px 0px 0px !important;
}

.draft-editor,
.preview-container {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 2.5em !important;
}

.preview-container .DraftEditor-root {
  padding: 0 !important;
}

.bg-slate-300 {
  background-color: rgb(203 213 225);
}

.sidebar-hide {
  transition: all width 2s;
}

.outbound {
  color: rgb(71, 85, 105);
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid rgb(226, 232, 240);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1.9819px 4.95475px;
}

.button-danger {
  height: 32px;
  border-radius: 3px;
  --tw-bg-opacity: 1;
  background-color: #dc3545;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 13px;
  font-weight: 500;

  color: white;
}

/* instantly  */

.shdaow-primary {
  box-shadow: -3px 12px 5px 0 #555;
}

.css-1u3vli6 {
  border-right-width: 0px !important;
}

.box-shadow-main {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.btn {
  padding: 8px 20px;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid #056EE9;
  color: #fff;

  background: linear-gradient(94.4deg, #7000ff 0%, #0c65c2 100%);
  box-shadow: 0px 0px 27.541px rgba(0, 0, 0, 0.15);
}

.btn:active {
  transform: scale(0.9);
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: #4349f0;
  transition: all 0.2s;
  color: white;
}

.button-normal{
  padding: 8px 20px;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid #006bff;
  color: #fff;
}


.css-3bmhjh-MuiPaper-root-MuiPopover-paper{
  box-shadow: 0 0 0 1px rgba(136,152,170,.1),0 15px 35px 0 rgba(49,49,93,.1),0 5px 15px 0 rgba(0,0,0,.08) !important;
}


.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2{
  padding: 8px 14px !important;
  font-weight: 600 !important;
  color: #333333 !important;
}

.MuiStack-root.css-160unip{
  padding-top: 0 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  overflow: hidden !important;
}

input#\:r3\:{
  padding: 8px 14px !important;
  font-weight: 600 !important;
  color: #333333 !important;
}


.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bn53lx{
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  overflow: hidden !important;
}