/* .active-list {
  color: red;
} */

.border-link {
  /* border-bottom: 3px solid ; */
  position: relative;
}

.border-link::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 5px;
  background-color: #0095ff;
  bottom: -9px;
  left: 0;
  border-radius: 3px;
}

/* search input typography  */

.input-search {
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-family: "Hind Siliguri", sans-serif;
  height: 40px;
  -webkit-tap-highlight-color: transparent;
  line-height: 34px;
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 14px;
  width: 85%;
  color: rgb(0, 0, 0);
  display: block;
  text-align: left;
  min-height: 36px;
  padding-left: 36px;
  padding-right: 12px;
}

.input-search:focus {
  outline: none;
  border-color: rgb(51, 154, 240);
}

.iSAVrt {
  background-color: #f7f7fc !important;
}
.dynHSE {
  padding: 0 !important;
  flex-direction: row-reverse !important;
  justify-content: left !important;
}

/* table  */

th {
  font-weight: 500;
  color: #11142d;
  font-size: 13px;
  opacity: 80%;
  height: 60px;
  text-align: start;
}

th,
td {
  color: #11142d;
  font-size: 13px;
}

.Ellipsis:hover {
  color: #0095ff;
  transition: all 0.2s;
}

.text-editor {
  margin: 0 auto;
}
.ql-editor {
  min-height: 100px;
}

.text-overflow {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  /* max-width: 400px; */

  margin: 0 auto;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* //.ql-editor */

/* .ql-custom{
  padding: px !important;
width: 30px !important;
height: 30px !important;
border: 1px solid #d3dae3 !important;
border-radius: 8px !important;
} */
#text-fromat {
  opacity: 0;
  visibility: hidden;
}

#brace:hover#text-fromat {
  opacity: 1;
  visibility: visible;
}

.ql-formats {
  margin-right: 0 !important;
}



.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
}
.react-datepicker-ignore-onclickoutside:focus {
  outline: none !important;
}



.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0095ff !important;
}

/* .react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  /* color: #26b9b9; */
/* .react-datetime-picker__clock {
  display: none;
} */

.my-datetime-picker .react-datetime-picker__inputGroup input:focus-visible {
  box-shadow: none;
  outline: none;
}

.my-datetime-picker .react-datetime-picker__amPm:focus {
  box-shadow: none;
  outline: none;
}

/* .date-picker-custom th,
.date-picker-custom td {
  border: none !important;
  padding: 4px !important;
} */

.date-picker-custom th,
td {
  border: none !important;
  padding: 4px !important;
}


.table.mx-auto.w-\[304px\] {
  margin-top: 20px !important;
}

.progress-wrapper {
  width: 50%;
  background-color: #ddd;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  background-color: #007bff;
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.fileNameParent {
  background-color: #f5f5f5;
  border: 1px solid transparent;
  font-weight: bold;
  margin: 0 0 9px;
  overflow-y: hidden;
  padding: 4px 4px 4px 8px;
  max-width: 448px;
}

.badge-small {
  display: inline-block;
  padding: 0.15em 0.35em;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
}

.heading-style {
  background-image: linear-gradient(to right, #5a0084, #2d64bc);
  color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}
